import React from "react";
import { Link } from "react-router-dom";
/*import blog3 from '../../images/blog-details/comments-author/img-1.jpg'*/
/*import blog4 from '../../images/blog-details/comments-author/img-2.jpg'*/
/*import blog5 from '../../images/blog-details/comments-author/img-3.jpg'*/
import blog6 from "../../images/blog-details/logopeq.png";
/*import gl1 from '../../images/blog/img-3.jpg'*/
/*import gl2 from '../../images/blog/img-2.jpg'*/
import blogs from "../../api/blogs";
import { useParams } from "react-router-dom";
import BlogSidebar from "../../components/BlogSidebar";

const BlogSingle = (props) => {
  const { id = "" } = useParams(1);
  console.log("🚀 ~ BlogSingle ~ id:", id);

  const BlogDetails = blogs.find((item) => item.id === id);

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const blogContent = [
    {
      id: 1,
      texto:<><p>
      Hoy en día, ese legado se ha preservado en Practicas como el
      Son de Negro Congolés o simplemente Son de Negro, un espacio
      de dialogo rítmico y folclor que acoge a esta comunidad. Las
      mujeres lucen polleras estampadas o de flores, calzan
      sandalias campesinas y se adornan el cabello con cayenas. Los
      hombres, por su parte, con sombreros campesinos cubiertos de
      papeles multicolores y calzando sandalias campesinas muestran
      con orgullo el torso al descubierto, untado con una mezcla de
      polvo mineral y aceite, y adornados con collares compuestos
      por diferentes materiales de la actividad pesquera y agrícola
      del sector. Es un lugar de encuentro que reúne y festeja el
      pasado y el presente de la comunidad, y en Santa Lucía se
      encuentra el mayor festival de esta danza. Sus orígenes se
      enmarcan en la colonia y buscaba encarnar el arraigo negro con
      una representación de los espacios de convivencia de las
      antiguas tribus guerreras del Congo. Se divide en tres
      momentos, el primero es el preámbulo o cabildo abierto,
      llamada donde se convoca a toda la comunidad a que se haga
      presente y se aliste. El segundo es el reposo, donde ya
      convocada la comunidad se la agrupa para realizar los
      diferentes ritmos que conforman las variaciones del Son de
      negro. Por último, la despedida forzosa, donde realizan una
      danza final de despedida y un “hasta pronto” (Pérez, 2017).
      Encuentro de saberes y fiesta que hacen parte de la identidad
      de este municipio. Todo esto posibilitado por el río, eje
      vertebrador de un municipio que ha sabido vivir a sus orillas.
      Santa Lucía ha crecido en base a la pesca y la agricultura
      proveídas por el río, se ha posicionado a través del
      transporte y comercio. por medio del Canal del Dique que
      conecta a Cartagena y su histórico puerto con el interior del
      país. Durante siglos ha surtido a la zona de todos los
      elementos básicos para la vida, y por eso constituía un lugar
      de refugio para aquellos cimarrones que buscaban subsistir por
      fuera del sistema esclavista. A pesar de nunca fue un entorno
      del todo sencillo, permitió prosperar a Santa Lucía como a
      muchos otros municipios ribereños, pero esta obra de
      ingeniería también marcaría a sus habitantes con una tragedia
      nunca antes vista.
    </p>
    <blockquote>
                  Fiestas, tradición y baile se enmarcan aquí. Santa lucía
                  constituye uno de los municipios ribereños del Canal del
                  Dique, una bifurcación artificial que se habilitó para proveer
                  a Cartagena de las aguas del río Magdalena en tiempos de la
                  colonia, y desde entonces fue lugar de acogida de múltiples
                  Palenques, de cimarrones en busca de su libertad. Aunque a día
                  de hoy, de todo este proceso solo se ha mantenido San Basilio
                  de Palenque, el poblamiento de estas zonas ha permitido que
                  Santa Lucía crezca como un espacio de sincretismos entre
                  diversas culturas y tradiciones que han sabido relatar su
                  historia.
                </blockquote>
                <p>
                  El desbordamiento del Canal del Dique en 2010 provocó la
                  inundación de múltiples municipios del sur del Atlántico y el
                  norte de Bolívar, por causa de un desprendimiento de tierra
                  debido a las fuertes lluvias que golpeaban a la región en ese
                  momento, aumentando el caudal del canal. Decenas de miles de
                  personas se encontraron repentinamente con el agua hasta el
                  pecho y con sus pertenencias completamente sumergidas,
                  paralizando a toda la comunidad. No era posible moverse por
                  sus calles por otro medio que no fueran canoas o lanchas. No
                  solo viviendas, el agua también había arrasado más de 32000
                  hectáreas de tierra fértil (Herrera, 2020), miles de personas
                  se vieron desplazadas de sus hogares y, aunque se hicieron
                  millonarios esfuerzos por reconstruir lo dañado, intensificó
                  el dolor de una población que desde años atrás ya sufría.
                  Santa Lucía es reconocido como un territorio víctima de
                  desplazamiento forzado durante el conflicto armado en Colombia
                  y esta tragedia solo acrecentaba la problemática. No obstante,
                  al igual que las aguas del río, las miles de familias
                  desplazadas de sus hogares poco a poco fueron retornando.
                  Miles de casas fueron reconstruidas, sus calles volvieron a
                  ser caminadas y sus colegios volvieron a abrir sus puertas.
                  Hoy en día Santa Lucía sigue transitando un proceso de
                  reparación, hace parte de la Ruta de la memoria que se
                  construye a lo largo de los diversos municipios del Canal del
                  Dique víctimas del conflicto armado, ordenado por la
                  Jurisdicción Especial para la Paz (JEP). Cada vez crece más el
                  interés de la comunidad para ser partícipes de estos procesos,
                  para ser partícipes de su destino. Lo han creado a partir de
                  la cultura, el Festival del Son de Negro volvió a estrechar
                  los vínculos de la comunidad lastimados por el desplazamiento
                  y la perdida. Hoy el río los vuelve a proveer de su sustento.
                  Los reúne y nos reúne para hacer memoria, de lo fueron y de lo
                  que son, de sus vivencias y de lo que hace parte de ser
                  santalucense
                </p>
            
    </> ,
        video: <iframe width="560" height="315" src="https://www.youtube.com/embed/ZzZPAGrN6kU?si=hjcK5MMho0TJCs3i" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    },
    {
      id: 2,
      texto: <> <p>En épocas de la colonia, hoy en día sumergido por el embalse, se encontraba el palenque San Benito de las Palomas, uno de los tantos palenques formados por cimarrones fugitivos para establecer comunidades libres y autónomas. Sin embargo, debido a problemas y retrasos en la construcción del Canal del Dique la depresión del Guájaro, donde se ubicaba el palenque, se empezó a inundar. Lo que obligó a sus pobladores a refugiarse en las áreas de cultivo que se encontraban un poco más alejadas; en sus “repelones”, como le llamaban (El Tiempo, 1998). Es ese el origen de los primeros pobladores de esta tierra, los que darían comienzo a este municipio, un tesoro cultural en el sur del Atlántico.
      El Canal del Dique ha sido ese lugar que ha movido la historia de Repelón. Desde las múltiples inundaciones que incluso lograron restablecer su ubicación, sumergiendo el palenque del que se origina, y posteriormente en 2010 volviendo a sumergir las viviendas de su población. Hasta ser la fuente de agua vital para sus cultivos de yuca, maíz, tabaco, algodón, caña de azúcar, dividivi y arroz, que los convirtieron durante la segunda mitad del siglo XIX y hasta mediados del siglo XX en la despensa agrícola del departamento. No solo cultivos sino también siendo un enclave pesquero que ha sido parte fundamental de la dieta local. El canal ha sido una zona clave de la historia del país y repelón ha sabido encontrarse en medio de este. El carácter de sus habitantes, su cultura y tradición son herencia del río, núcleo de este municipio.
      Por otro lado, sus fiestas patronales denotan la importancia histórica de la religión en el municipio, ya desde los tiempos del palenque. Originalmente esta fe encontró su patrono en San Benito de Palermo, religioso africano, hijo de esclavos, canonizado en 1807. Pero como lo muestra el nombramiento del palenque, reconocido ampliamente desde tiempos anteriores. Ya constituido Repelón, San Antonio de Padua se erige como el nuevo patrono de sus habitantes. 
      Cada 13 de junio con procesiones y ceremonias permiten a los participantes expresar su devoción y mantener vivas las tradiciones espirituales del municipio. De la misma manera, durante este día tan esperado que convoca tanto a locales como visitantes en las calles y plazas del pueblo, también se llenan de vida con una variada oferta de actividades que reflejan la riqueza cultural y el entusiasmo de su gente.
      </p>
      <blockquote>
      Llegó a ser conocida como la despensa agrícola del Atlántico. Su cercanía al embalse del Guájaro le dotaba de un sistema de riego ideal para la agricultura, actualmente, su nombre refleja ese legado.
                </blockquote>
      <p>
      Las cabalgatas, por un lado, desfilan majestuosamente a través de las avenidas, ofreciendo un espectáculo colorido y festivo que celebra las tradiciones ecuestres del lugar. No menos importantes son los encuentros deportivos, que se convierten en una plataforma para la competencia sana entre los habitantes y los visitantes. Además de otros eventos variados, que abarcan desde danzas típicas hasta desafíos gastronómicos, que añaden un elemento de diversión y alegría al día festivo. Celebrando así el vibrante mosaico de talentos y tradiciones que caracterizan al municipio. En conjunto, estas festividades crean una experiencia inolvidable que une a la comunidad y resalta el carácter único del lugar.
No obstante, no todo ha sido alegría en Repelón. Episodios de violencia dejaron una huella imborrable en la comunidad, con desplazamientos forzados, pérdidas humanas y una sensación general de inseguridad que afectó gravemente la vida cotidiana de sus habitantes.  A día de hoy, todavía son recordadas las dos incursiones del Bloque Norte de las Autodefensas Unidas de Colombia en el corregimiento de Pita. 
A pesar de ello, el municipio ha sido testigo de un notable esfuerzo por parte de sus líderes comunitarios y autoridades locales para fomentar la reconciliación y la construcción de una paz duradera. Las iniciativas en Repelón se han centrado en la promoción del diálogo, la reparación de víctimas y la consolidación de la presencia del Estado para garantizar la seguridad y el desarrollo. Repelón hace parte de la Ruta de la memoria, ordenada por la Jurisdicción Especial para la Paz (JEP), que se construye a lo largo de los diversos municipios del Canal del Dique víctimas del conflicto armado.
La experiencia de Pita y su trayectoria en el conflicto se han convertido en un testimonio importante de la resiliencia y la capacidad de recuperación de las comunidades afectadas. Actos, como la vigilia por la paz cada 29 de septiembre, y la presencia de entidades como el Instituto Colombiano de Bienestar Familiar (ICBF), espacios de acompañamiento psicosocial y fomento de proyectos productivos dan cuenta de ello (Unidad de Víctimas, 2020).
Es un proceso largo el que ha constituido lo que hoy es Repelón. Aquí, su gente hace memoria de su historia y de su cultura. No como algo ya escrito, sino que se sigue escribiendo, o más bien narrando. Así es como su comunidad día a día sigue construyendo la identidad del municipio.

                </p>
      </>,
      video:<iframe width="560" height="315" src="https://www.youtube.com/embed/3zpezKRVc3Q?si=k58J4gCYnoBw9vVx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    },
    {id: 3,
        texto: <> <p>“Sí la vida nada vale / pajarito de la ma’ / ¿cuál será la solución? / pajarito de la ma’ / ¿para qué tanta violencia? / pajarito de la ma’ / con eso no se saca na’ / pajarito de la ma’”
        Se dice que originalmente estas tierras eran conocidas como Mahabana, en honor a un cacique indígena llamado Mahada. Posteriormente, durante la colonia se refunda como Villa de San Luis Beltrán, en honor al santo dominico que había pisado sus tierras durante sus misiones de evangelización. Este nombre no solo reflejaba el impacto religioso y cultural de la misión, sino que también simbolizaba el vínculo espiritual entre el santo y la comunidad emergente (Caballero, 2022).    
        </p>
        <blockquote>
        Sin embargo, la identidad de la villa experimentó una transformación notable cuando un inesperado suceso cambió su denominación para siempre. Un día, unos pescadores locales encontraron un manatí en la ciénaga cercana, un hallazgo tan sorprendente y singular que capturó la imaginación de todos los habitantes. 
                  </blockquote>
        <p>Este mamífero acuático, conocido por su tamaño imponente y su comportamiento pacífico, se convirtió en un símbolo de asombro y fascinación. El descubrimiento del manatí fue tan impactante que los habitantes decidieron cambiar el nombre de la villa en su honor, marcando así un giro en la identidad del lugar. La elección del nombre, de inspiración tan azarosa, subraya la manera en que lo inesperado ha moldeado el carácter de esta comunidad. Tierra del Son de Pajarito, Manatí es un centro de cultura en la ribera del Canal del Dique.
El color, las polleras, babuchas y flores en el cabello se destacan en las mujeres, mientras que los hombres lucen sombreros, pantalones negros o blancos, camisas de colores, y andan en abarcas o descalzos; todos elementos de su tradición y cotidianidad que conforman las vestimentas características de este baile (Pérez, 2010). En octubre, la ciudad se transforma en la capital del Son de Pajarito, albergando el festival más importante de esta danza típica. Conocido de esta forma por la manera en la que las mujeres mueven sus polleras durante el baile, imitando el vuelo de los pájaros, y evocando una imagen de libertad y vida. 
Igual de importante que el baile es la música con la que lo acompaña. Ahí los “instrumentos típicos como el tambor alegre, tambor llamador, guaches o maracas, claves o tablitas, palmas y voces que imponen el ritmo musical, con versos que expresan los sucesos de la vida cotidiana” (Romero, 2020) a través de sus músicos, cantaoras y cantaores. El espectáculo musical, con su vibrante combinación de sonidos y ritmos, inunda las calles del municipio con una energía contagiosa y una alegría desbordante. 
De igual manera, y a pesar del cambio de nombre, Manatí sigue vibrando con una rica tradición religiosa. Desde el 29 de noviembre, la comunidad se despierta a las 4 de la madrugada para acompañar a la Inmaculada Concepción, patrona del pueblo, en su recorrido por los barrios que la solicitan. Cada barrio se adorna festivamente, y la plaza principal se llena de vida y entusiasmo mientras los vecinos siguen a la virgen. Las celebraciones continúan por la noche con fandangos en la plaza, manteniendo viva una tradición que une y alegra a todo el pueblo. 
Por otra parte, el nombre más reciente también hace parte de la identidad cultural del municipio. Al igual que muchos de los municipios ribereños del Canal del Dique, este ha sido clave para su desarrollo. Desde proveer agua dulce para abastecer a sus cultivos, como fuente de alimentos en su tradicional práctica pesquera. Práctica que dio origen al nombre del municipio como se mencionaba más arriba. Pero, también ha sido lugar de origen de múltiples inundaciones del municipio cuando arrecian las temporadas invernales. 
Su historia solo se puede entender a través de las aguas del canal. Son estas aguas, provenientes del río magdalena las que dan la clave para comprender los procesos que se viven en el municipio. El único municipio que ha vivido una toma guerrillera en el departamento del Atlántico, autoría del EPL (Trejos, 2020). Pero, también un municipio que ha vivido un amplio proceso de reparación. Visto en el pago de indemnizaciones a las víctimas de una violencia que los ha marginado del país y los ha anulado como ciudadanos de una nación democrática (Unidad de Víctimas, 2018). Y también en el ordenamiento de la Justicia Especial para la Paz (JEP), de construir una Ruta de la memoria que busque continuar el proceso reparador para los habitantes de municipios víctimas del conflicto armado en el Canal del Dique. Pues la memoria se ha convertido en una herramienta clave de justicia, reparación, movilización y cambio de las comunidades. Un proceso transformador de realidades que hace posible el reabrir espacios de diálogo que conforman el sentir de una comunidad.
</p> 
            
        </>,

        video:<iframe width="560" height="315" src="https://www.youtube.com/embed/3zpezKRVc3Q?si=k58J4gCYnoBw9vVx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    }
  ].find((item) => item.id == id);
  console.log("🚀 ~ BlogSingle ~ blogContent:", blogContent);

  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              <div className="post format-standard-image">
                <div className="entry-media">
                  <img src={BlogDetails.blogSingleImg} alt="" />
                </div>
                <div className="entry-meta">
                  <ul>
                    <li>
                      <i className="fi flaticon-user"></i> By{" "}
                      <Link to="/blog-single/1">{BlogDetails.author}</Link>{" "}
                    </li>
                    <li>
                      <i className="fi flaticon-comment-white-oval-bubble"></i>{" "}
                      Comments {BlogDetails.comment}
                    </li>
                    <li>
                      <i className="fi flaticon-calendar"></i>{" "}
                      {BlogDetails.create_at}
                    </li>
                  </ul>
                </div>
                <h2>{BlogDetails.title}</h2>
                {blogContent.texto}

                {blogContent.video}
                
              </div>

              <div className="tag-share clearfix">
                <div className="tag">
                  <span>Buscar: </span>
                  <ul>
                    <li>
                      <Link to="/blog-single/1">Donation</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">Charity</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">Education</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tag-share-s2 clearfix">
                <div className="tag">
                  <span>buscanos: </span>
                  <ul>
                    <li>
                      <Link to="/blog-single/1">facebook</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">twitter</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">linkedin</Link>
                    </li>
                    <li>
                      <Link to="/blog-single/1">pinterest</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="author-box">
                <div className="author-avatar">
                  <Link to="/blog-single/1" target="_blank">
                    <img src={blog6} alt="" style={{ maxWidth: "120px" }} />
                  </Link>
                </div>
                <div className="author-content">
                  <Link to="/blog-single/1" className="author-name">
                    Author: DigniCo
                  </Link>
                  <p>
                    Nuestro objetivo es empoderar comunidades, generar
                    conciencia y liderar cambios sostenibles que promuevan la
                    justicia y la equidad.
                  </p>
                  <div className="socials">
                    <ul className="social-link">
                      <li>
                        <Link to="https://www.facebook.com/DigniColombia">
                          <i className="ti-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="https://x.com/DigniColombia">
                          <i className="ti-twitter-alt"></i>
                        </Link>
                      </li>        
                      <li>
                        <Link to="https://www.instagram.com/dignicolombia">
                          <i className="ti-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/*<div className="more-posts">
                <div className="previous-post">
                  <Link to="/blog">
                    <span className="post-control-link">Previous Post</span>
                    <span className="post-name">
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium.
                    </span>
                  </Link>
                </div>
                <div className="next-post">
                  <Link to="/blog-left-sidebar">
                    <span className="post-control-link">Next Post</span>
                    <span className="post-name">
                      Dignissimos ducimus qui blanditiis praesentiu deleniti
                      atque corrupti quos dolores
                    </span>
                  </Link>
                </div>
              </div>

               <div className="comments-area">
                                <div className="comments-section">
                                    <h3 className="comments-title">Comments</h3>
                                    <ol className="comments">
                                        <li className="comment even thread-even depth-1" id="comment-1">
                                            <div id="div-comment-1">
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src={blog3} alt="" /></div>
                                                </div>
                                                 <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>John Abraham <span className="comments-date">January 12,2021
                                                                At 9.00am</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>I will give you a complete account of the system, and
                                                                expound the actual teachings of the great explorer of
                                                                the truth, </p>
                                                            <div className="comments-reply">
                                                                <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="children">
                                                <li className="comment">
                                                    <div>
                                                        <div className="comment-theme">
                                                            <div className="comment-image"><img src={blog4} alt="" /></div>
                                                        </div>
                                                        <div className="comment-main-area">
                                                            <div className="comment-wrapper">
                                                                <div className="comments-meta">
                                                                    <h4>Lily Watson <span className="comments-date">January
                                                                        12,2021 At 9.00am</span></h4>
                                                                </div>
                                                                <div className="comment-area">
                                                                    <p>I will give you a complete account of the system,
                                                                        and expound the actual teachings of the great
                                                                        explorer of the truth, </p>
                                                                    <div className="comments-reply">
                                                                        <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li className="comment">
                                                            <div>
                                                                <div className="comment-theme">
                                                                    <div className="comment-image"><img src={blog5} alt="" /></div>
                                                                </div>
                                                                <div className="comment-main-area">
                                                                    <div className="comment-wrapper">
                                                                        <div className="comments-meta">
                                                                            <h4>John Abraham <span className="comments-date">January
                                                                                12,2021 At 9.00am</span></h4>
                                                                        </div>
                                                                        <div className="comment-area">
                                                                            <p>I will give you a complete account of the
                                                                                system, and expound the actual teachings
                                                                                of the great explorer of the truth, </p>
                                                                            <div className="comments-reply">
                                                                                <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="comment">
                                            <div>
                                                <div className="comment-theme">
                                                    <div className="comment-image"><img src={blog3} alt="" /></div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>John Abraham <span className="comments-date">January 12,2021
                                                                At 9.00am</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>I will give you a complete account of the system, and
                                                                expound the actual teachings of the great explorer of
                                                                the truth, </p>
                                                            <div className="comments-reply">
                                                                <Link className="comment-reply-link" to="/blog-single/1"><span>Reply</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                                <div className="comment-respond">
                                    <h3 className="comment-reply-title">Post Comments</h3>
                                    <form onSubmit={submitHandler} id="commentform" className="comment-form">
                                        <div className="form-textarea">
                                            <textarea id="comment" placeholder="Write Your Comments..."></textarea>
                                        </div>
                                        <div className="form-inputs">
                                            <input placeholder="Website" type="url" />
                                            <input placeholder="Name" type="text" />
                                            <input placeholder="Email" type="email" />
                                        </div>
                                        <div className="form-submit">
                                            <input id="submit" value="Post Comment" type="submit" />
                                        </div>
                                    </form>
                                </div>
                            </div>*/}
            </div>
          </div>
          <BlogSidebar blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
