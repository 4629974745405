import React from "react";
import { Link } from 'react-router-dom'
import Clients from './client'


import hero1 from '../../images/slider/imagen-hero.jpg'
import hero2 from '../../images/slider/s.png'
import hero3 from '../../images/slider/imagen-hero3.png'




const Hero =() => {
    return (
        <section className="wpo-hero-section-1">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col col-xs-6 col-lg-6">
                        <div className="wpo-hero-section-text">
                            <div className="wpo-hero-title-top">
                                <span>Construyamos un país más justo y equitativo</span>
                            </div>
                            <div className="wpo-hero-title">
                                <h2> A través de la promoción de los derechos humanos y la intervención social.</h2>
                            </div>
                            <div className="wpo-hero-subtitle">
                                <p>Desde programas educativos hasta iniciativas para proteger a las poblaciones vulnerables, conoce los proyectos que lideramos y cómo trabajamos en red con aliados estratégicos para transformar vidas.</p>
                            </div>
                            <div className="btns">
                                <Link to="/about" className="btn theme-btn-s2">Conócenos</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="right-vec">
                            <div className="right-items-wrap">
                                <div className="right-item">
                                    <div className="r-img">
                                        <img src={hero1} alt=""/>
                                    </div>
                                    <div className="wpo-happy-client">
                                        <Clients/>
                                    </div>
                                </div>
                                <div className="right-item">
                                    <div className="wpo-total-project">
                                        <div className="wpo-total-project-wrap">
                                            <div className="wpo-total-project-icon"> /
                                                <i className="fi flaticon-checked"></i>
                                            </div>
                                            <div className="wpo-total-project-text">
                                                <h3>252+</h3>
                                                <p>Total Projects</p>
                                            </div>
                                        </div>
                                        <div className="project-shape">
                                            <img src={hero2} alt=""/>
                                        </div>
                                    </div>
                                    <div className="r-img">
                                        <img src={hero3} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}



export default Hero;
