import React from 'react'
import {Link} from  'react-router-dom'
import abimg from '../../images/imagen-hero1.jpg'
import shape from '../../images/ab-shape-2.png'


const About = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


    return(
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                        <img src={abimg} alt=""style={{marginTop:'20px'}}/>
                                <div className="wpo-ab-shape-1">
                                    <div className="s-s1"></div>
                                    <div className="s-s2"></div>
                                </div>
                                <div className="wpo-ab-shape-2"><img src={shape} alt=""/></div>
                               {/* <div className="wpo-total-raised">
                                    <div className="wpo-total-raised-wrap">
                                        <div className="wpo-total-raised-icon">
                                            <i className="fi flaticon-wallet-filled-money-tool"></i>
                                        </div>
                                        <div className="wpo-total-raised-text">
                                            <ul>
                                                <li>Total Raised<span>$25000</span></li>
                                            </ul>
                                            <div className="progress-section">
                                                <div className="process">
                                                    <div className="progress">
                                                        <div className="progress-bar">
                                                            <div className="progress-value"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <span>Sobre nosotros</span>
                            <h2>Derechos humanos: el eje de nuestra labor.</h2>
                            <p>En "Colombia Unida por la Dignidad", creemos que un país digno comienza con el respeto por los derechos fundamentales. Profundizando en nuestras acciones para empoderar a comunidades y promover políticas inclusivas.

</p>
                            <ul>
                                <li>Educación en derechos humanos.</li>
                                <li>Apoyo a poblaciones vulnerables</li>
                                <li>Programas de desarrollo comunitario.</li>
                                <li>Defensa legal y acompañamiento</li>
                            </ul>
                            <Link onClick={ClickHandler} className="theme-btn-s2" to="/about">Unete</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;