import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/logopeq1.png'
/*import Projects from '../../api/projects'*/

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className="wpo-site-footer">
        <div className="wpo-upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                            <div className="logo widget-title">
                                <img src={Logo} alt="blog" style={{maxWidth:'120px', alignItems:'center'}}/>
                            </div>
                            <p>En "Colombia Unida por la Dignidad", trabajamos incansablemente para fomentar una sociedad justa y equitativa, donde cada individuo pueda ejercer plenamente sus derechos fundamentales.</p>
                            <ul>
                                <li>
                                    <Link to="https://www.facebook.com/DigniColombia">
                                        <i className="ti-facebook"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://x.com/DigniColombia">
                                        <i className="ti-twitter-alt"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/dignicolombia?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                                        <i className="ti-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/">
                                        <i className="ti-google"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Sobre Nosotros </h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                {/*<li><Link onClick={ClickHandler} to="/cause">Causes</Link></li>*/}
                                <li><Link onClick={ClickHandler} to="/blog">Latest News</Link></li>
                                <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                                <li><Link onClick={ClickHandler} to="/event">Events</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget wpo-service-link-widget">
                            <div className="widget-title">
                                <h3>Contactanos </h3>
                            </div>
                            <div className="contact-ft">
                                <p>Si tienes preguntas, requieres más información no dudes en contactar</p>
                                <ul>
                                    <li><i className="fi flaticon-mail"></i>relacionespublicas@dignicolombia.org</li>
                                    <li><i className="fi flaticon-phone-call"></i>+573183852728</li>
                                    <li><i className="fi flaticon-location"></i>Calle 81 # 35d - 67</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    {/*<div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                        <div className="widget instagram">
                            <div className="widget-title">
                                <h3>Projects</h3>
                            </div>
                            <ul className="d-flex">
                                {Projects.slice(0,6).map((project, pitem)=>(
                                    <li className="grid" key={pitem}>
                                        <div className="img-holder">
                                            <Link onClick={ClickHandler} to={`/project-single/${project.id}`}>
                                                <img src={project.projectImg} alt=""/>
                                            </Link>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>*/}
                </div>
            </div>
        </div>
        <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className="copyright"> &copy; 2021 DigniCo. Design By Eiamek. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;