import timg1 from '../images/team/Migue.png'
import timg2 from '../images/team/Nathalie.png'
import timg3 from '../images/team/José.png'
import timg4 from '../images/team/Juliana.png'
import timg5 from '../images/team/Angie.png'
{/*import timg6 from '../images/team/6.jpg'
import timg7 from '../images/team/7.jpg'
import timg8 from '../images/team/8.jpg'
import timg9 from '../images/team/9.jpg'
import timg10 from '../images/team/10.jpg'*/}


const Teams = [
    {
       id:'1',
       tImg:timg1,
       name:'Miguel Ariza',
       title:'Abogado',     
    },
    {
       id:'2',
       tImg:timg2,
       name:'Nathalie Torres',
       title:'psicóloga',     
    },
    {
       id:'3',
       tImg:timg3,
       name:'Jose Guzman',
       title:'Comunicador Social',     
    },
    {
       id:'4',
       tImg:timg4,
       name:'Juliana Tapia',
       title:'psicóloga',     
    },
    {
       id:'5',
       tImg:timg5,
       name:'Angie Hinestroza',
       title:'Abogada',     
    },
    {/*{
       id:'6',
       tImg:timg6,
       name:'Devid Harmin',
       title:'Volunteer',     
    },
    {
       id:'7',
       tImg:timg7,
       name:'Maria Belziana',
       title:'Volunteer',     
    },
    {
       id:'8',
       tImg:timg8,
       name:'Robert Chirls',
       title:'Volunteer',     
    },
    {
       id:'9',
       tImg:timg9,
       name:'William Frost',
       title:'CEO',     
    },
    {
       id:'10',
       tImg:timg10,
       name:'Zenefer Marry',
       title:'Manager',     
    }*/}
    
]

export default Teams;