import React from 'react'
import {Link} from  'react-router-dom'
import abimg from '../../images/imagen-hero2.jpg'


const AboutS2 = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }


    return(
        <section className="wpo-about-section-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={abimg} alt=""/>
                                </div>   
                                <div className="wpo-total-raised">
                                    {/*<div className="wpo-total-raised-wrap"></div>*/}
                                       {/*<div className="wpo-total-raised-icon">
                                            <i className="fi flaticon-wallet-filled-money-tool"></i>
                                        </div>*/}
                                     {/* <div className="wpo-total-raised-text">
                                            <ul>
                                                <li>Total Raised<span>$25000</span></li>
                                            </ul>
                                        </div>*/}
                                            {/*<div className="progress-section"> </div>*/}
                                               {/* <div className="process"> </div>*/}
                                                   {/* <div className="progress"> </div>*/}
                                                        <div className="progress-bar">
                                                            <div className="progress-value"></div>
                                                    
                                                        </div>
                                            
                                        
                                
                                <div className="round-ball-1"></div>
                                <div className="round-ball-2"></div>
                                <div className="round-ball-3"></div>
                                <div className="round-ball-4"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <span>Sobre nosotros</span>
                            <h2>Derechos humanos: el eje de nuestra labor.</h2>
                            <p>En "Colombia Unida por la Dignidad", creemos que un país digno comienza con el respeto por los derechos fundamentales. Profundizando en nuestras acciones para empoderar a comunidades y promover políticas inclusivas. </p>
                            <ul>
                                <li>Educación en derechos humanos.</li>
                                <li>Apoyo a poblaciones vulnerables</li>
                                <li>Programas de desarrollo comunitario.</li>
                                <li>Defensa legal y acompañamiento</li>
                            </ul>
                            <Link onClick={ClickHandler} className="theme-btn-s2" to="/about">More About</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutS2;