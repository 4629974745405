import React from 'react';
import {Link} from 'react-router-dom'
import blogs from '../../api/blogs'
import Services from '../../api/service';
import Projects from '../../api/projects'
import about from '../../images/blog/logopeq.png'


const BlogSidebar = (props) => {

    const SubmitHandler = (e) =>{
       e.preventDefault()
    }

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <div className={`col col-lg-4 col-md-12 col-12 ${props.blLeft}`}>
            <div className="blog-sidebar">
                <div className="widget about-widget">
                    <div className="img-holder">
                        <img src={about} alt=""/>
                    </div>
                    <h4>DigniCo</h4>
                    <p>Desde la fundación queremos promover las historias de los municipios de la region caribe</p>
                    <div className="social">
                        <ul className="clearfix">
                            <li><Link onClick={ClickHandler} to="https://www.facebook.com/DigniColombias"><i className="ti-facebook"></i></Link></li>
                            <li><Link onClick={ClickHandler} to="https://x.com/DigniColombia"><i className="ti-twitter-alt"></i></Link></li>
                            <li><Link onClick={ClickHandler} to="https://www.linkedin.com/company/colombia-unida-por-la-dignidad"><i className="ti-linkedin"></i></Link></li>
                            <li><Link onClick={ClickHandler} to="https://www.instagram.com/dignicolombia?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><i className="ti-instagram"></i></Link></li>
                        </ul>
                    </div>
                    <div className="aw-shape">
                    </div>
                </div>
                <div className="widget search-widget">
                    <form onSubmit={SubmitHandler}>
                        <div>
                            <input type="text" className="form-control" placeholder="Search Post.."/>
                            <button type="submit"><i className="ti-search"></i></button>
                        </div>
                    </form>
                </div>
                {/*<div className="widget category-widget">
                    <h3>Categories</h3>
                    <ul>
                        {Services.slice(0,6).map((service, Sitem) => (
                            <li key={Sitem}><Link onClick={ClickHandler} to={`/service-single/${service.id}`}>{service.title} <span>{service.id}</span></Link></li>
                        ))}
                    </ul>
                </div>*/}
                <div className="widget recent-post-widget">
                    <h3>Otros relatos</h3>
                    <div className="posts">
                        {blogs.slice(0, 3).map((blog, Bitem) => (
                            <div className="post" key={Bitem}>
                                <div className="img-holder">
                                    <img src={blog.screens} alt="" />
                                </div>
                                <div className="details">
                                    <h4><Link onClick={ClickHandler} to={`/blog-single/${blog.id}`}>{blog.title}</Link></h4>
                                    <span className="date">{blog.create_at}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/*<div className="widget wpo-instagram-widget">
                    <div className="widget-title">
                        <h3>Projects</h3>
                    </div>
                    <ul className="d-flex">
                        {Projects.slice(0,6).map((project, pitem)=>(
                            <li className="grid" key={pitem}>
                                <div className="img-holder">
                                    <Link onClick={ClickHandler} to={`/project-single/${project.id}`}>
                                        <img src={project.projectImg} alt=""/>
                                    </Link>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="widget tag-widget">
                    <h3>Tags</h3>
                    <ul>
                        <li><Link onClick={ClickHandler} to="/cause-single/1">Charity</Link></li>
                        <li><Link onClick={ClickHandler} to="/cause-single/1">Planning</Link></li>
                        <li><Link onClick={ClickHandler} to="/cause-single/1">Helping</Link></li>
                        <li><Link onClick={ClickHandler} to="/cause-single/1">Education</Link></li>
                        <li><Link onClick={ClickHandler} to="/cause-single/1">Medical</Link></li>
                        <li><Link onClick={ClickHandler} to="/cause-single/1">Wildlife</Link></li>
                        <li><Link onClick={ClickHandler} to="/cause-single/1">Donation</Link></li>
                        <li><Link onClick={ClickHandler} to="/cause-single/1">Help</Link></li>
                        <li><Link onClick={ClickHandler} to="/cause-single/1">World Pandamic</Link></li>
                    </ul>
                </div>*/}
                <div className="wpo-contact-widget widget">
                        <h2>¿Quieres ayudar? <br/> Escribenos!</h2>
                        <p>Si te interesan los voluntariados o simplemente quieres ayudar una buena causa contactanos </p>
                        <Link onClick={ClickHandler} to="/contact">Contactanos</Link>
                </div>
            </div>
        </div>
     )
        
}

export default BlogSidebar;
